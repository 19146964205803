import React from 'react'
import { Container } from 'semantic-ui-react'
import "./styles.scss"

export const ContactForm = () => {
    return (<Container className="dash-board">
        hello from Contact form
            </Container>
    )
}
